<script>
export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {};
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {},
  methods: {
    closeMode() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <!-- TS Info -->
  <b-modal v-model="showModal" centered hide-footer hide-header>
    <div class="row">
      <div class="col-md-12">
        <b-card-title class="mb-4">
          <div class="float-right">
            <i
              class="bx bx-x font-size-20"
              style="cursor: pointer"
              @click="closeMode()"
            ></i>
          </div>
          <h4 class="card-title mb-3">Time-Score</h4>
        </b-card-title>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-3">
        {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text1") }}
      </div>
      <div class="col-md-12 mb-3">
        {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text2") }}
      </div>
      <div class="col-md-12 mb-3">
        {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text3") }}
      </div>
      <div class="col-md-12 mb-4">
        {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text41") }}
        <p>
          {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text42") }}
          <br />
          {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text43") }}
          <br />
          {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text44") }}
          <br />
          {{ $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.text45") }}
        </p>
      </div>
      <div class="col-md-12 mb-3">
        {{
          $t("qualityScoreWidgets.timeScoreWidget.detailedInfo.useCase.title")
        }}
        <ul>
          <li>
            {{
              $t(
                "qualityScoreWidgets.timeScoreWidget.detailedInfo.useCase.text1"
              )
            }}
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>
