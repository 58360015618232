<script>
import {
  MeanChart,
  DistributionChart,
  TrafficLightChart,
  TimeScorePerformanceChart
} from "@/redem/datafiles/result-charts";
import DatafileResultService from "@/api/services/datafileResult.service";
import { ALGORITHM_TYPES } from "@/shared/util/const";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";

/*****
 * Socail Desirability Quality Widget
 */
export default {
  props: {
    datafileId: String,
    cleaned: Boolean,
    cardBackgroundColor: String
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      /******* Data Variables *******/
      chartDataForMeanChart: 0,
      meanChartOptions: null,
      chartDataForTrafficLightChart: [],
      trafficLightChartOptions: null,
      chartDataForDistributionChart: null,
      rateChartData: {},
      distributionChartOptions: null,
      performanceChartOptions: null,
      chartDataForPerformanceChart: {
        name: "Average Social Desrability Score",
        data: []
      },
      /******* Operational Variables *******/
      isDisplayInfo: false,
      isDisplayData: false,
      isDisplayTrafficLightError: false,
      isDisplayDistributionError: false,
      isDisplayPerformanceDataError: false,
      isDisplayRateError: false
    };
  },
  async created() {
    // set mean chart, charts options
    this.meanChartOptions = new MeanChart();
    this.trafficLightChartOptions = new TrafficLightChart();
    this.distributionChartOptions = new DistributionChart();
    this.performanceChartOptions = new TimeScorePerformanceChart();

    const trafficLight = this.fetchTrafficLightData();
    const distribution = this.fetchDistributionData();
    const performance = this.fetchSDSPerformanceData();
    const rate = this.fetchSDSRate();

    try {
      await trafficLight;
    } catch (e) {
      this.isDisplayTrafficLightError = true;
      console.log("Error fetching SDS traffic light data");
    }
    try {
      await distribution;
    } catch (e) {
      this.isDisplayDistributionError = true;
      console.log("Error fetching SDS distribution data");
    }

    try {
      await performance;
    } catch (e) {
      this.isDisplayPerformanceDataError = true;
      console.log("Error fetching SDS performance data");
    }

    try {
      await rate;
    } catch (e) {
      this.isDisplayRateError = true;
      console.log("Error fetching SDS rate data");
    }

    this.isDisplayData = true;
    this.$emit("dataFetchingDone");
    this.$emit("fetchingFinished", ALGORITHM_TYPES.SDS);
  },
  methods: {
    async fetchTrafficLightData() {
      let tempData = await DatafileResultService.getTrafficLightData(
        this.datafileId,
        ALGORITHM_TYPES.SDS,
        null,
        this.cleaned
      );
      tempData = tempData.trafficLightValues;
      const trafficLightData = [
        tempData.Red,
        tempData.Yellow,
        tempData.LightGreen,
        tempData.Green,
        tempData.Grey
      ];

      this.chartDataForTrafficLightChart = trafficLightData;
      this.chartDataForMeanChart = Math.round(tempData.Average);
    },

    async fetchDistributionData() {
      const tempDistributionData = await DatafileResultService.getDistributionData(
        this.datafileId,
        ALGORITHM_TYPES.SDS,
        null,
        this.cleaned
      );
      this.chartDataForDistributionChart = [
        {
          name: "Data-Records",
          data: tempDistributionData.scoreDistribution
        }
      ];
    },

    async fetchSDSPerformanceData() {
      const tempPerformanceData = await DatafileResultService.getQualityScorePerformanceData(
        this.datafileId,
        "All",
        this.cleaned,
        ALGORITHM_TYPES.SDS
      );
      this.chartDataForPerformanceChart.data = tempPerformanceData;
    },

    async fetchSDSRate() {
      const tempRate = await DatafileResultService.getSDSRate(
        this.datafileId,
        null,
        this.cleaned
      );

      this.rateChartData = {
        positive: tempRate.Positive,
        negative: tempRate.Negative
      };
    },

    changeToShowInfo() {
      this.isDisplayInfo = true;
    },

    changeToShowData() {
      this.isDisplayInfo = false;
    },

    clickViewMoreButton() {
      this.$emit("viewMore", ALGORITHM_TYPES.SDS);
    }
  }
};
</script>
<template>
  <div
    class="card animate__animated animate__fadeInUp animate__slow"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="" v-if="isDisplayData">
      <!-- Results -->
      <div class="card-body" v-if="!isDisplayInfo">
        <b-card-title class="pb-1">
          <div class="float-right" v-if="this.cleaned">
            <b-button
              class="btn-sm"
              variant="light"
              @click="clickViewMoreButton()"
              >{{
                $t("datafileResultGeneral.qualityScoreWidget.showMoreButton")
              }}
              <i class="bx bx-right-arrow-alt ml-1"></i
            ></b-button>
          </div>
          <h4 class="card-title mb-1">
            {{ $t("qualityScoreWidgets.socialDesirabilityScoreWidget.title") }}
            <i
              class="bx bxs-info-circle font-size-18 text-info bx-tada ml-2"
              style="cursor: pointer"
              @click="changeToShowInfo()"
            ></i>
          </h4>
        </b-card-title>

        <div class="row mb-3 mt-3">
          <div class="col-md-12">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.description"
              )
            }}
          </div>
        </div>

        <!-- Tab Version -->
        <div class="row mt-5">
          <div class="col-md-12">
            <b-tabs>
              <!-- SDS Rate -->
              <b-tab active lazy>
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">
                    {{
                      $t(
                        "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.title"
                      )
                    }}
                  </span>
                </template>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <div class="mb-3">
                      {{
                        $t(
                          "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.description"
                        )
                      }}
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <span>
                          <i class="bx bxs-circle text-danger"> </i>
                          {{
                            $t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.socialDesirableLabel"
                            )
                          }}%
                        </span>
                        <span class="ml-3">
                          <i class="bx bxs-circle text-success"> </i>
                          {{
                            $t(
                              "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.rate.notSocialDesirableLabel"
                            )
                          }}%
                        </span>
                      </div>
                    </div>
                    <b-progress
                      v-if="!isDisplayRateError"
                      class="mt-4"
                      :max="100"
                      height="30px"
                      style="font-size: 15px"
                    >
                      <b-progress-bar
                        :value="rateChartData.negative"
                        variant="danger"
                        show-value
                      ></b-progress-bar>
                      <b-progress-bar
                        :value="rateChartData.positive"
                        variant="success"
                        show-value
                      ></b-progress-bar>
                    </b-progress>
                    <div v-else class="mt-3 text-center">
                      <DataFetchingErrorWidget />
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Genral -->
              <b-tab lazy>
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">
                    {{
                      $t(
                        "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.genral"
                      )
                    }}
                  </span>
                </template>
                <div class="row mt-3">
                  <!-- SDS Score -->
                  <div class="col-md-4">
                    <div v-if="!isDisplayTrafficLightError">
                      <apexchart
                        ref="PSMeanChart"
                        class="apex-charts apexChartData mt-5"
                        type="radialBar"
                        height="300"
                        dir="ltr"
                        :series="[chartDataForMeanChart]"
                        :options="meanChartOptions.chartOptions"
                      ></apexchart>
                    </div>
                    <div v-else class="text-center">
                      <DataFetchingErrorWidget />
                    </div>
                  </div>
                  <!-- Distribution Chart -->
                  <div class="col-md-4">
                    <div v-if="!isDisplayDistributionError">
                      <apexchart
                        ref="PSDistributionChart"
                        class="apex-charts mt-5"
                        dir="ltr"
                        type="area"
                        height="300"
                        :series="chartDataForDistributionChart"
                        :options="distributionChartOptions.chartOptions"
                      ></apexchart>
                    </div>
                    <div v-else class="text-center">
                      <DataFetchingErrorWidget />
                    </div>
                  </div>
                  <!-- Traffic Light Chart -->
                  <div class="col-md-4">
                    <div v-if="!isDisplayTrafficLightError">
                      <apexchart
                        ref="PStrafficLightChart"
                        class="apex-charts mt-5 mb-4"
                        height="300"
                        type="pie"
                        dir="ltr"
                        :series="chartDataForTrafficLightChart"
                        :options="trafficLightChartOptions.chartOptions"
                      ></apexchart>
                    </div>
                    <div v-else class="text-center">
                      <DataFetchingErrorWidget />
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Proformace Chart-->
              <b-tab
                lazy
                v-if="this.chartDataForPerformanceChart.data.length > 1"
              >
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "qualityScoreWidgets.socialDesirabilityScoreWidget.tabs.performance"
                    )
                  }}</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div v-if="!isDisplayPerformanceDataError">
                      <apexchart
                        ref="performanceChart"
                        class="apex-charts"
                        dir="ltr"
                        type="bar"
                        height="300"
                        :series="[chartDataForPerformanceChart]"
                        :options="performanceChartOptions.chartOptions"
                      ></apexchart>
                    </div>
                    <div v-else class="text-center">
                      <DataFetchingErrorWidget />
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="card-body" v-else>
        <div class="row mb-3">
          <div class="col-md-10">
            <h4 class="card-title mb-3">
              {{
                $t("qualityScoreWidgets.socialDesirabilityScoreWidget.title")
              }}
              <i
                class="bx bxs-x-circle font-size-18 text-info bx-tada ml-2"
                style="cursor: pointer"
                @click="changeToShowData()"
              ></i>
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text1"
              )
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text2"
              )
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text3"
              )
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text4"
              )
            }}
          </div>
          <div class="col-md-12 mb-3">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.text5"
              )
            }}
          </div>
          <div class="col-md-12">
            {{
              $t(
                "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.useCase.title"
              )
            }}
            <ul>
              <li>
                {{
                  $t(
                    "qualityScoreWidgets.socialDesirabilityScoreWidget.detailedInfo.useCase.text1"
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
