<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatapointWidget from "@/redem/datafiles/components/widgets/IS/IS-datapoints-widget.vue";
import PositiveNegativeWidget from "@/redem/datafiles/components/widgets/IS/is-positive-negative-widget-reactive.vue";

export default {
  components: {
    PositiveNegativeWidget,
    DatapointWidget
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: null,
      /******** Operational Variables *********/
      isDisplayMainLoader: true,
      alreadyLoadedComponents: 0,
      numberOfComponentToBeLoaded: 2
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
  },
  methods: {
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === this.numberOfComponentToBeLoaded)
        this.isDisplayMainLoader = false;
    }
  }
};
</script>

<template>
  <div>
    <!-- Loading Component -->
    <div v-if="this.isDisplayMainLoader">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <div v-show="!this.isDisplayMainLoader">
      <!-- Positive Negetive Widget -->
      <div class="row">
        <div class="col-md-12">
          <PositiveNegativeWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :dataPointIdentifier="All"
            :isCleaned="true"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Datapoint Widgets-->
      <div class="row">
        <div class="col-md-12">
          <DatapointWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :isCleaned="true"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>
    </div>
  </div>
</template>
