<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import DatafileService from "@/api/services/datafile.service.js";
import DatafileResultService from "@/api/services/datafileResult.service.js";
import ManageReportModal from "@/redem/datafiles/components/modals/manage-datafile-report.vue";
import QualityScoresModal from "@/redem/datafiles/components/modals/quality-scores-modal.vue";
import QuotaSettingsInfoModal from "@/redem/datafiles/components/modals/quota-settings-info-modal.vue";
import DataRecordsSummaryWidget from "@/redem/datafiles/components/widgets/data-records-summary-widget.vue";
import ISQualityScoreWidget from "@/redem/datafiles/components/widgets/IS/IS-quality-widget.vue";
import QuotaSettingsWidget from "@/redem/datafiles/components/widgets/quota-settings-widget.vue";
import ReportWidget from "@/redem/datafiles/components/widgets/report-widget.vue";
import SDSQualityScoreWidget from "@/redem/datafiles/components/widgets/SDS/SDS-quality-widget.vue";
import RScoreWidgetInfoModal from "@/shared/components/model/r-score-widget-info.vue";

import { EventBus } from "@/shared/util/event-bus.js";
import {
checkDatafileSize,
initializeSocketBufferTimer
} from "@/shared/util/helpers.js";
// Const
import MetaDataWidget from "@/redem/datafiles/components/widgets/meta-data-widget.vue";
import RScoreDistributionWidget from "@/redem/datafiles/components/widgets/RScore/R-score-distribution-widget.vue";
import RScoreTrafficLightWidget from "@/redem/datafiles/components/widgets/RScore/R-score-traffic-light-widget.vue";
import RScoreWidget from "@/redem/datafiles/components/widgets/RScore/R-score-widget.vue";
import {
MAX_REQUEST_TIME,
WEBSOCKET_UPDATE_INTERVAL
} from "@/shared/util/const.js";
import cookie from "vue-cookies";

/**
 * Cleaned Results
 */
export default {
  components: {
    RScoreWidgetInfoModal,
    QuotaSettingsWidget,
    QuotaSettingsInfoModal,
    ReportWidget,
    ManageReportModal,
    QualityScoresModal,
    ISQualityScoreWidget,
    SDSQualityScoreWidget,
    RScoreWidget,
    RScoreDistributionWidget,
    RScoreTrafficLightWidget,
    MetaDataWidget,
    DataRecordsSummaryWidget
  },
  data() {
    return {
      /*******  Data varibales ********/
      datafileId: null,
      trafficLightData: [],
      scoreDistributionData: [],
      qualityScoreStats: {},
      rScorePieChartData: null,
      dbDatafile: {},
      algorithms: null,
      /******** Operational Variables *********/
      customWidgetWidth: "col-md-4 col-lg-4",
      showRScoreInfoModel: false,
      showManageReprotModal: false,
      showQuotaSettingsInfoModel: false,
      socket: null,
      websocketBuffer: 0,
      websocketUpdateInterval: WEBSOCKET_UPDATE_INTERVAL,
      maxRequestTime: MAX_REQUEST_TIME,
      isDisplayMainLoading: true,
      alreadyLoadedComponents: 0,
      numberOfComponentToBeLoaded: 7,
      isDisplayQualityScoreModal: false,
      isDisplayQuotaSettingsWidget: true
    };
  },
  async created() {
    // Get data file ID from URL & Fetch Datafile from DB
    this.datafileId = this.$route.query.datafileId;
    this.dbDatafile = await DatafileService.getDatafile(this.datafileId);

    // check datafile and import type, if import type is not XLXS disable Quota Widget
    if (this.dbDatafile && this.dbDatafile.importType !== "XLXS") {
      this.numberOfComponentToBeLoaded -= 1;
      this.isDisplayQuotaSettingsWidget = false;
      this.customWidgetWidth = "col-md-6 col-lg-6";
    }

    // Fetch available algorithms for datafile
    this.algorithms = await DatafileService.getAlgorithmsInDatafile(
      this.datafileId
    );

    if (this.algorithms.IS) this.numberOfComponentToBeLoaded += 1;
    if (this.algorithms.SDS) this.numberOfComponentToBeLoaded += 1;
    // if (this.dbDatafile.certificateLevel) this.numberOfComponentToBeLoaded += 1;

    // If it's a live survey, subscribe to database updates here and trigger a re-render whenever a respondent gets added
    if (this.dbDatafile.isLiveCleaning === "RUNNING") {
      this.initializeWebSocketSubscription();
      initializeSocketBufferTimer(this);
    }

    EventBus.$on("closeCleanedResults", () => {
      this.destroySocket();
    });
  },
  beforeDestroy() {
    this.destroySocket();
  },
  methods: {
    countTheComponents() {
      this.alreadyLoadedComponents += 1;

      if (this.alreadyLoadedComponents === this.numberOfComponentToBeLoaded)
        this.isDisplayMainLoading = false;
    },
    destroySocket() {
      if (this.socket) {
        this.socket.close(1000, String(this.datafileId) + ";CLEANED");
        this.socket = null;
      }
    },
    initializeWebSocketSubscription() {
      const cleanedValues = true;
      const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER);
      this.socket = socket;
      const token = cookie.get("accessToken");
      socket.onopen = async () => {
        await checkDatafileSize(this, cleanedValues);
        console.log(
          "[open][overview-results.vue] Connection to WebSocketServer on port 8002 established"
        );
        socket.send(this.datafileId + ";CLEANED;" + token);
        console.log(
          `[overview-results.vue]: websocket update interval: ${this.websocketUpdateInterval}`
        );
      };
      socket.onmessage = async (event) => {
        console.log(
          "[message][overview-results.vue]: Received message from socket"
        );
        // Check if records are there if they are 0
        if (this.dbDatafile.numberOfCleanedRecords === 0) {
          DatafileService.getDatafile(this.datafileId).then((datafile) => {
            this.dbDatafile = datafile;
          });
        }

        if (this.websocketBuffer < 1) {
          this.websocketBuffer++;
          // console.log(`[message][overview-results.vue] Data received from server: ${event.data}`);
          DatafileResultService.getTrafficLightData(
            this.datafileId,
            "R",
            null,
            cleanedValues
          )
            .then((data) => {
              this.trafficLightData = [
                data.Red,
                data.Yellow,
                data.LightGreen,
                data.Green,
                data.Grey
              ];
              EventBus.$emit("updateTrafficLightData", this.trafficLightData);
            })
            .catch((e) => {
              this.errorTrafficLight = true;
            });
          DatafileResultService.getDistributionData(
            this.datafileId,
            "R",
            null,
            cleanedValues
          )
            .then((data) => {
              this.scoreDistributionData = data;
              EventBus.$emit(
                "updateScoreDistributionData",
                this.scoreDistributionData
              );
            })
            .catch((e) => {
              this.errorScoreDistribution = true;
            });
          let t1 = new Date();
          let t2;
          DatafileResultService.getQualityScore(
            this.datafileId,
            cleanedValues
          )
            .then((data) => {
              if (data.requestQueue > 10) {
                const ADD_TO_BUFFER = 2;
                console.log(
                  `Request queue is long, pausing requests for: ${
                    (ADD_TO_BUFFER * this.websocketUpdateInterval) / 1000
                  } seconds.`
                );
                this.websocketBuffer += ADD_TO_BUFFER;
              }
              t2 = new Date();
              const dT = t2 - t1;
              if (dT > this.maxRequestTime && this.websocketBuffer < 2) {
                let ADD_TO_BUFFER = 2;
                if (dT > this.maxRequestTime * 10) ADD_TO_BUFFER = 6;
                console.log(
                  `[overview-results.vue]: Backend server is responding very slowly. Pausing requests for ${
                    ADD_TO_BUFFER * (this.websocketUpdateInterval / 1000)
                  } seconds.`
                );
                this.websocketUpdateInterval += 500;
                console.log(
                  `[overview-results.vue]: Increasing update interval to ${this.websocketUpdateInterval} ms`
                );
                this.websocketBuffer += ADD_TO_BUFFER;
              }
              console.log("[overview-results.vue]: deltaT: ", dT);
              this.qualityScoreStats = data;
              EventBus.$emit(
                "updateQualityScoreStatData",
                this.qualityScoreStats
              );
            })
            .catch((e) => {
              this.errorRScoreData = true;
            });
          DatafileService.getDatafileMetadata(
            this.datafileId,
            cleanedValues
          ).then(() => {
            EventBus.$emit("updateDatafileMetadata");
          });
          EventBus.$emit("updateQuotaWidget");
        }
      };
    },
    showRScoreInfo(show) {
      this.showRScoreInfoModel = show;
    },
    clickViewMoreQulityScore(qualityScore) {
      this.$emit("changeTab", qualityScore);
    },
    showQuotaSettingsInfo(show) {
      this.showQuotaSettingsInfoModel = show;
    },
    showManageReprot(show) {
      this.showManageReprotModal = show;
    },
    async reloadPage() {
      if (this.$route.query.activateRawTab) {
        await this.$router.push(
          `/datafile-result?datafileId=${this.datafileId}`
        );
      }

      window.location.reload();
    },
    showQualityScoresModel() {
      this.isDisplayQualityScoreModal = true;
    },
    quotaSettingWidgetAvaiability() {
      this.isDisplayQuotaSettingsWidget = false
      this.customWidgetWidth = "col-md-6 col-lg-6";
    }
  }
};
</script>

<template>
  <div>
    <!-- Loading Components -->
    <div v-if="this.isDisplayMainLoading">
      <div class="row">
        <div class="col-12 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <!-- Result Components -->
    <div v-show="!this.isDisplayMainLoading">
      <!-- Meta data card -->
      <div class="row">
        <div class="col-md-12">
          <MetaDataWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :cleaned="true"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Header Row -->
      <div class="row">
        <div :class="customWidgetWidth">
          <DataRecordsSummaryWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :cleaned="true"
            :isCleaningApply="dbDatafile.isCleaned"
            @dataFetchingDone="countTheComponents"
            :onlyOneQulityScore="false"
          />
        </div>
        <div :class="customWidgetWidth">
          <ReportWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :datafileTitle="this.dbDatafile.title"
            :datafile="this.dbDatafile"
            @displayManageReport="showManageReprot(true)"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <div class="col-md-4" v-if="this.dbDatafile.importType === 'XLXS' && isDisplayQuotaSettingsWidget">
          <QuotaSettingsWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            @showQuotaInfo="showQuotaSettingsInfo(true)"
            @dataFetchingDone="countTheComponents"
            @quotaSettingStatus="quotaSettingWidgetAvaiability"
          />
        </div>
      </div>

      <!-- R-Score widgets -->
      <div class="row">
        <!-- R-Score Mean & Presentation Charts -->
        <div class="col-lg-4">
          <RScoreWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :cleaned="true"
            @showQualityScores="this.showQualityScoresModel"
            @showRScoreInfo="showRScoreInfo(true)"
            @closeRScoreInfo="showRScoreInfo(false)"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <!-- R-Score Distribution Chart -->
        <div class="col-lg-4">
          <RScoreDistributionWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :cleaned="true"
            @dataFetchingDone="countTheComponents"
          />
        </div>
        <!-- R-Score Traffic Light Chart -->
        <div class="col-lg-4">
          <RScoreTrafficLightWidget
            class="animate__animated animate__fadeInUp animate__slow"
            :datafileId="this.datafileId"
            :cleaned="true"
            @dataFetchingDone="countTheComponents"
          />
        </div>
      </div>

      <!-- Other Quality Scores -->
      <div class="row" v-if="algorithms.IS || algorithms.SDS">
        <div class="col-md-12 mt-3 mb-4"><hr /></div>
        <div class="col-12" v-show="algorithms.IS">
          <ISQualityScoreWidget
            :datafileId="this.datafileId"
            :cleaned="true"
            @dataFetchingDone="countTheComponents"
            @viewMore="clickViewMoreQulityScore"
          />
        </div>
        <div class="col-12" v-show="algorithms.SDS">
          <SDSQualityScoreWidget
            :datafileId="this.datafileId"
            :cleaned="true"
            @dataFetchingDone="countTheComponents"
            @viewMore="clickViewMoreQulityScore"
          />
        </div>
      </div>
    </div>

    <!-- R-Score Info widget Model -->
    <RScoreWidgetInfoModal
      v-if="showRScoreInfoModel"
      :visible="showRScoreInfoModel"
      @close="showRScoreInfo(false)"
    />

    <!-- Quota Settings Info widget Model -->
    <QuotaSettingsInfoModal
      v-if="showQuotaSettingsInfoModel"
      :visible="showQuotaSettingsInfoModel"
      @close="showQuotaSettingsInfo(false)"
    />

    <!-- Manage Report Modal -->
    <ManageReportModal
      :visible="this.showManageReprotModal"
      :datafileId="this.datafileId"
      @close="showManageReprotModal = false"
      @reload="reloadPage()"
    />

    <!-- Quality Score Modal -->
    <QualityScoresModal
      v-if="isDisplayQualityScoreModal"
      :visible="this.isDisplayQualityScoreModal"
      :datafileId="this.datafileId"
      :isCleanedDatafile="true"
      @close="isDisplayQualityScoreModal = false"
      @clickViewMoreQualitySocre="this.clickViewMoreQulityScore"
    />
  </div>
</template>
