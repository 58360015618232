<script>
import DatafileResultService from "@/api/services/datafileResult.service.js";
import { TrafficLightChart } from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  props: {
    datafileId: String,
    cleaned: Boolean,
    toggleBenchmark: Boolean
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      rScoreTrafficLightChartData: null,
      rScoreTrafficLighChartOptions: null,
      benchmarkTrafficLightChartData: [],
      displayBenchmarkChart: false,
      /******* Operational Varaibles *****/
      isDisplayData: false,
      isDisplayRScoreTrafficLightError: false,
      benchmarkDataNotAvailable: false
    };
  },
  computed: {
    propoverMethodInfo() {
      return {
        html: true,
        content: () =>
        this.$i18n.t("datafileResultGeneral.trafficLightWidget.benchmarkInfo")
      };
    } 
  },
  watch: {
    async toggleBenchmark(newValue) {
      this.displayBenchmarkChart = newValue;
      await this.fetchAllData();
    }
  },
  async created() {
    this.rScoreTrafficLighChartOptions = new TrafficLightChart();
    await this.fetchAllData();
    this.$emit("dataFetchingDone");
    EventBus.$on("updateTrafficLightData", (trafficLightData) => {
      this.rScoreTrafficLightChartData = trafficLightData;
      const chartSeries = [...trafficLightData];
      if (this.$refs.trafficLightChart) this.$refs.trafficLightChart.updateSeries(chartSeries, true);
    });
  },
  methods: {
    async fetchAllData() {
      this.benchmarkDataNotAvailable = false;
      this.isDisplayData = false;
      this.isDisplayRScoreTrafficLightError = false;
      try {
        const response = await DatafileResultService.getTrafficLightData(this.datafileId, "R", null, this.cleaned, null, this.displayBenchmarkChart);

        if(response && response.trafficLightValues) {
          this.rScoreTrafficLightChartData = []
          this.rScoreTrafficLightChartData = [
            response.trafficLightValues.Red,
            response.trafficLightValues.Yellow,
            response.trafficLightValues.LightGreen,
            response.trafficLightValues.Green,
            response.trafficLightValues.Grey,
          ]
        } 

        if(response && response.benchmarkTrafficLightValues) {
          this.benchmarkTrafficLightChartData = []
          this.benchmarkTrafficLightChartData = [
            response.benchmarkTrafficLightValues.Red,
            response.benchmarkTrafficLightValues.Yellow,
            response.benchmarkTrafficLightValues.LightGreen,
            response.benchmarkTrafficLightValues.Green,
            response.benchmarkTrafficLightValues.Grey,
          ]
        }

        this.isDisplayData = true;
      } catch (error) {
        this.isDisplayData = true;
        if (error.code === "BENCHMARK_NOT_FOUND") {
          this.benchmarkDataNotAvailable = true;
          this.isDisplayRScoreTrafficLightError = true;
        } else {
          this.isDisplayRScoreTrafficLightError = true;
        }
      }
    },
  }
};
</script>

<template>
  <div
    class="card"
    style="height: 450px"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="isDisplayData">
      <!-- Card Title  -->
      <div class="card-title">
        {{ $t("datafileResultGeneral.trafficLightWidget.title") }}
        <!-- Info Button -->
        <i v-if="displayBenchmarkChart"
          v-b-popover.hover="this.propoverMethodInfo"
          class="ml-2 bx bxs-info-circle text-info"
          style="cursor: pointer"
        ></i>            
      </div>

      <!-- Card Description  -->
      <div class="card-text mt-4">
        {{ $t("datafileResultGeneral.trafficLightWidget.description") }}
      </div>

      <!-- Card Data -->
      <div class="card-text mt-4">
        <div class="row">
          <!-- Regular Traffic Light Chart -->
          <div class="col-12"
            v-if="
              (!isDisplayRScoreTrafficLightError && this.cleaned && !displayBenchmarkChart) ||
              (!isDisplayRScoreTrafficLightError && !this.cleaned && !displayBenchmarkChart)
            ">
            <apexchart
              ref="trafficLightChart"
              class="apex-charts"
              height="300"
              type="donut"
              dir="ltr"
              :series="rScoreTrafficLightChartData"
              :options="rScoreTrafficLighChartOptions.chartOptions"
            ></apexchart>
          </div>
          <!-- Benchmark Traffic Light Chart -->
          <div class="col-12" v-else-if="!isDisplayRScoreTrafficLightError && !this.cleaned && displayBenchmarkChart">
            <h6> {{$t("datafileResultRawTab.benchmarkWidget.yourData")}}: </h6>
            <b-progress class="mt-2" :max="100" show-value height="30px" style="font-size: 15px">
              <b-progress-bar :value="rScoreTrafficLightChartData[0]" variant="danger"></b-progress-bar>
              <b-progress-bar :value="rScoreTrafficLightChartData[1]" variant="warning"></b-progress-bar>
              <b-progress-bar :value="rScoreTrafficLightChartData[2]" variant="average"></b-progress-bar>
              <b-progress-bar :value="rScoreTrafficLightChartData[3]" variant="success"></b-progress-bar>
            </b-progress>

            <h6 class="mt-3"> {{$t("datafileResultRawTab.benchmarkWidget.benchmark")}}: </h6>
            <b-progress class="mt-2" :max="100" show-value height="30px" style="font-size: 15px">
              <b-progress-bar :value="benchmarkTrafficLightChartData[0]" variant="danger"></b-progress-bar>
              <b-progress-bar :value="benchmarkTrafficLightChartData[1]" variant="warning"></b-progress-bar>
              <b-progress-bar :value="benchmarkTrafficLightChartData[2]" variant="average"></b-progress-bar>
              <b-progress-bar :value="benchmarkTrafficLightChartData[3]" variant="success"></b-progress-bar>
            </b-progress>

            <div class="row mt-5">
              <div class="col-3 text-center"> <i class='bx bxs-circle text-danger'></i> {{ $t("datafileResultRawTab.benchmarkWidget.range1") }}</div>
              <div class="col-3 text-center"> <i class='bx bxs-circle text-warning'></i> {{ $t("datafileResultRawTab.benchmarkWidget.range2") }} </div>
              <div class="col-3 text-center"> <i class='bx bxs-circle text-average'></i> {{ $t("datafileResultRawTab.benchmarkWidget.range3") }} </div>
              <div class="col-3 text-center"> <i class='bx bxs-circle text-success'></i> {{ $t("datafileResultRawTab.benchmarkWidget.range4") }}</div>
            </div>
          </div>
          <div v-else-if="benchmarkDataNotAvailable" class="text-center col-12 mt-2">
            <b-alert
              show
              variant="info"
            >
              {{ $t("datafileResultGeneral.benchmarkNotAvailable") }}
            </b-alert>
          </div>
          <div v-else>
            <DataFetchingErrorWidget />
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
