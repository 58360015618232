<script>
import DatafileResultService from "@/api/services/datafileResult.service.js";
import {
MeanChart,
RScorePresentationChart,
BenchmarkRScoreChart
} from "@/redem/datafiles/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import { EventBus } from "@/shared/util/event-bus.js";
export default {
  props: {
    datafileId: String,
    cleaned: Boolean,
    toggleBenchmark: Boolean
  },
  components: {
    DataFetchingErrorWidget
  },
  data() {
    return {
      rScoreChartData: null,
      rScoreChartOptions: null,
      rScorePresentationChartData: null,
      rScorePresentationChartOptions: null,
      displayBenchmarkChart: false,
      rScoreBenchmarkChartData: null,
      rScoreBenchmarkChartOptions: null,
      /******* Operational Varaibles *****/
      isDisplayData: false,
      isDisplayRScoreError: false,
      isDisplayRScorePresentationError: false,
      benchmarkDataNotAvailable: false,
    };
  },
  watch: {
    async toggleBenchmark(newValue) {
      this.displayBenchmarkChart = newValue;
      await this.fetchAllData();
    }
  },
  async created() {
    this.rScoreChartOptions = new MeanChart();
    this.rScorePresentationChartOptions = new RScorePresentationChart();
    this.rScoreBenchmarkChartOptions = new BenchmarkRScoreChart()
    await this.fetchAllData(true);
    this.$emit("dataFetchingDone");
    EventBus.$on("updateQualityScoreStatData", async (qualityScoreStatData) => {
      const chartSeries = [Math.round(qualityScoreStatData.Mean)];
      this.rScoreChartData = qualityScoreStatData.Mean;
      if (this.$refs.meanChart) this.$refs.meanChart.updateSeries(chartSeries, true);
      await this.fetchRScorePresentationChartData();
    });
  },
  methods: {
    async fetchAllData() {
      this.isDisplayData = false;
      this.benchmarkDataNotAvailable = false;
      this.isDisplayRScoreError = false;
      const rScoreMean = this.fetchRScoreData();
      const rScorePresentation = this.fetchRScorePresentationChartData();

      try {
        await rScoreMean;
      } catch (e) {
        if (e.code === "BENCHMARK_NOT_FOUND") {
          this.benchmarkDataNotAvailable = true;
          this.isDisplayRScoreError = true;
        } else {
          this.isDisplayRScoreError = true;
          console.log("Error fetching R-Score data");
        }  
      }

      try {
        await rScorePresentation;
      } catch (e) {
        this.isDisplayRScorePresentationError = true;
        console.log("Error fetching R-Score Presentation data");                
      }
      this.isDisplayData = true;
    },
    async fetchRScoreData() {
      const response = await DatafileResultService.getAverageQualityScoreData(this.datafileId, "R", null, this.cleaned, null, this.displayBenchmarkChart);

      if(response && response.average) {
        this.rScoreChartData = response.average;
      }

      if(response && response.benchmarkAverage) {
        this.rScoreBenchmarkChartData = response.benchmarkAverage;
      }
    },
    async fetchRScorePresentationChartData() {
      const response = await DatafileResultService.getRscorePieChartData(
        this.datafileId
      );

      this.rScorePresentationChartData = [
        response.OES,
        response.TS,
        response.IBS,
        response.PS,
        response.CS
      ];
    },
    clickViewMoreButton() {
      this.$emit("showQualityScores");
    },
    clickInfoRScoreWidget() {
      if (this.isDisplayRScoreWidgetData) {
        this.isDisplayRScoreWidgetData = false;
        this.$emit("showRScoreInfo");
      } else {
        this.isDisplayRScoreWidgetData = true;
        this.$emit("closeRScoreInfo");
      }
    }
  }
};
</script>

<template>
  <div
    class="card"
    style="height: 450px"
    :style="[
      this.cleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body" v-if="isDisplayData">
      <!-- Card Title  -->
      <div class="card-title">
        <!-- Read More Button -->
        <div v-if="!displayBenchmarkChart" class="float-right">
          <b-button
            class="btn-sm"
            variant="light"
            @click="clickViewMoreButton()"
            >{{
              $t("datafileResultGeneral.qualityScoreWidget.showMoreButton")
            }}
            <i class="bx bx-right-arrow-alt ml-1"></i
          ></b-button>
        </div>
        {{ $t("datafileResultGeneral.rScoreWidget.title") }}
        <i
          v-if="!this.showRScoreInfoProp"
          class="bx bxs-info-circle font-size-16 text-info bx-tada"
          style="cursor: pointer"
          @click="clickInfoRScoreWidget()"
        ></i>
        <i
          v-else
          class="bx bxs-x-circle font-size-16 text-info bx-tada"
          style="cursor: pointer"
          @click="clickInfoRScoreWidget()"
        ></i>
      </div>

      <!-- Card Description  -->
      <div class="card-text mt-4">
        {{ $t("datafileResultGeneral.rScoreWidget.description") }}
      </div>

      <!-- Card Data -->
      <div class="card-text mt-3">
        <div class="row">
          <div class="col-12">
            <b-tabs v-if="!displayBenchmarkChart">
              <!-- General Tab -->
              <b-tab active lazy>
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">
                    {{ $t("datafileResultGeneral.rScoreWidget.tab.meanLabel") }}
                  </span>
                </template>
                <div>
                  <!-- Regular R-Score Chart -->
                  <div
                    v-if="
                      (!isDisplayRScoreError && this.cleaned && !displayBenchmarkChart) ||
                      (!isDisplayRScoreError && !this.cleaned && !displayBenchmarkChart)
                    "
                  class="mt-3">
                    <apexchart
                      ref="meanChart"
                      class="apex-charts apexChartData"
                      type="radialBar"
                      height="280"
                      dir="ltr"
                      :series="[Math.round(this.rScoreChartData)]"
                      :options="rScoreChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <!-- Benchmark R-Score Chart -->
                  <div class="col-12" v-else-if="!isDisplayRScoreError && !this.cleaned && displayBenchmarkChart">
                    <apexchart
                      ref="meanChart"
                      class="apex-charts apexChartData"
                      type="radialBar"
                      height="280"
                      dir="ltr"
                      :series="[Math.round(this.rScoreChartData), Math.round(this.rScoreBenchmarkChartData)]"
                      :options="rScoreBenchmarkChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <div v-else-if="benchmarkDataNotAvailable" class="text-center col-12 mt-2">
                    <b-alert
                      show
                      variant="info"
                    >
                      {{ $t("datafileResultGeneral.benchmarkNotAvailable") }}
                    </b-alert>
                  </div>
                  <div v-else class="text-center mt-3">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>

              <!-- Presentation Tab -->
              <b-tab lazy v-if="!displayBenchmarkChart">
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">
                    {{
                      $t(
                        "datafileResultGeneral.rScoreWidget.tab.rScorePresentation"
                      )
                    }}
                  </span>
                </template>
                <div>
                  <div v-if="!isDisplayRScorePresentationError">
                    <apexchart
                      ref="RScorePresenattionChart"
                      class="apex-charts mt-4"
                      height="280"
                      type="pie"
                      dir="ltr"
                      :series="rScorePresentationChartData"
                      :options="rScorePresentationChartOptions.chartOptions"
                    ></apexchart>
                  </div>  
                  <div v-else class="text-center mt-3">
                    <DataFetchingErrorWidget />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div v-else>
              <!-- Regular R-Score Chart -->
              <div
                v-if="
                  (!isDisplayRScoreError && this.cleaned && !displayBenchmarkChart) ||
                  (!isDisplayRScoreError && !this.cleaned && !displayBenchmarkChart)
                "
              class="mt-3">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="280"
                  dir="ltr"
                  :series="[Math.round(this.rScoreChartData)]"
                  :options="rScoreChartOptions.chartOptions"
                ></apexchart>
              </div>
              <!-- Benchmark R-Score Chart -->
              <div class="col-12" v-else-if="!isDisplayRScoreError && !this.cleaned && displayBenchmarkChart">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="280"
                  dir="ltr"
                  :series="[Math.round(this.rScoreChartData), Math.round(this.rScoreBenchmarkChartData)]"
                  :options="rScoreBenchmarkChartOptions.chartOptions"
                ></apexchart>
                <!-- Information section -->
                <div class="d-flex justify-content-center mt-3">
                  <div class="mx-2">
                    {{ $t("datafileResultGeneral.yourData") }}: <strong> {{ Math.round(this.rScoreChartData) }}</strong> 
                  </div>
                  <div class="mx-2">
                    {{ $t("datafileResultGeneral.benchmark") }}: <strong> {{ Math.round(this.rScoreBenchmarkChartData) }} </strong>
                  </div>
                </div>
              </div>
              <div v-else-if="benchmarkDataNotAvailable" class="text-center col-12 mt-2">
                <b-alert
                  show
                  variant="info"
                >
                  {{ $t("datafileResultGeneral.benchmarkNotAvailable") }}
                </b-alert>
              </div>
              <div v-else class="text-center mt-3">
                <DataFetchingErrorWidget />
              </div>
            </div>              
          </div>
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>
