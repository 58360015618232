import { render, staticRenderFns } from "./CS-metadata-widget.vue?vue&type=template&id=e2aa50c8"
import script from "./CS-metadata-widget.vue?vue&type=script&lang=js"
export * from "./CS-metadata-widget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/aselawijesinghe/Documents/Projects/redem-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2aa50c8')) {
      api.createRecord('e2aa50c8', component.options)
    } else {
      api.reload('e2aa50c8', component.options)
    }
    module.hot.accept("./CS-metadata-widget.vue?vue&type=template&id=e2aa50c8", function () {
      api.rerender('e2aa50c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/datafiles/components/widgets/CS/CS-metadata-widget.vue"
export default component.exports