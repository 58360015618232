import { render, staticRenderFns } from "./raw-results.vue?vue&type=template&id=0231b1b8"
import script from "./raw-results.vue?vue&type=script&lang=js"
export * from "./raw-results.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/aselawijesinghe/Documents/Projects/redem-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0231b1b8')) {
      api.createRecord('0231b1b8', component.options)
    } else {
      api.reload('0231b1b8', component.options)
    }
    module.hot.accept("./raw-results.vue?vue&type=template&id=0231b1b8", function () {
      api.rerender('0231b1b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/datafiles/components/tabs/raw-results.vue"
export default component.exports