var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      style: [
        this.cleaned ? { background: "#FFFFFF" } : { background: "#FFF5F4" },
        this.cleaned ? { height: "150px" } : { height: "250px" },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "6" } }, [
                !_vm.isEnableToEditDatafileTitle
                  ? _c(
                      "span",
                      {
                        staticClass: "hover-on-datafile-title",
                        on: {
                          click: function ($event) {
                            return _vm.clickEditDatafileTitle()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.metadata.title) + " ")]
                    )
                  : _vm._e(),
                _vm.isEnableToEditDatafileTitle
                  ? _c("span", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          name: "datafileTitle",
                          placeholder: "Edit datafile title",
                        },
                        domProps: { value: _vm.metadata.title },
                        on: {
                          change: function ($event) {
                            return _vm.changeDatafileTitle(
                              _vm.metadata.title,
                              $event
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { sm: "6" } },
                [
                  this.datafile.isWeighted
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "mr-2",
                          staticStyle: { "font-size": "12px" },
                          attrs: { variant: "primary" },
                        },
                        [
                          _c("i", { staticClass: "bx bx-slider mr-1" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultCleanedTab.metadataWidget.weightedTag"
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.datafile.isLiveCleaning === _vm.liveCleaningStatus.running
                    ? _c(
                        "b-badge",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { variant: "light" },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "bx bxs-circle bx-flashing text-success",
                          }),
                          _c("span", { staticClass: "text-success" }, [
                            _vm._v(" LIVE "),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.datafile.isLiveCleaning === _vm.liveCleaningStatus.stop
                    ? _c(
                        "b-badge",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { variant: "light" },
                        },
                        [
                          _c("i", { staticClass: "bx bxs-flag-checkered" }),
                          _c("span", {}, [_vm._v(" FINISHED ")]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "card-text mt-2" }, [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("datafileResultRawTab.metadataWidget.uploadedBy")
                  ) +
                  " " +
                  _vm._s(_vm.metadata.createdBy) +
                  " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-text mt-4" },
            [
              _c(
                "b-row",
                [
                  !this.cleaned
                    ? _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultRawTab.metadataWidget.numberOfDataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfRecords)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  !this.cleaned
                    ? _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultRawTab.metadataWidget.numberOfInvalidDataRecords"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfInvalidRecords)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultRawTab.metadataWidget.numberOfDataPoints"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.metadata.numberOfDatapoints)),
                      ]),
                    ]),
                  ]),
                  !_vm.onlyOneQulityScore
                    ? _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultRawTab.metadataWidget.numberOfQualityScores"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.metadata.numberOfQualityScores)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultRawTab.metadataWidget.dateOfCreation"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            String(_vm.metadata.dateOfCreation).slice(0, 10)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultRawTab.metadataWidget.creditCount"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.metadata.cost.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                            })
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.onlyOneQulityScore && _vm.availableQualityScore === "OES"
                    ? _c("b-col", { staticClass: "mb-3", attrs: { sm: "2" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultRawTab.metadataWidget.contextCheck"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm.metadata.isEnableWrongTopicCheck
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultRawTab.metadataWidget.yes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.metadata.isEnableWrongTopicCheck
                          ? _c(
                              "span",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultRawTab.metadataWidget.no"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.metadata.missingValues &&
                  _vm.metadata.missingValues.length > 0
                    ? _c("b-col", { staticClass: "mb-3", attrs: { sm: "4" } }, [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultCleanedTab.metadataWidget.missingValues"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          _vm._l(
                            _vm.metadata.missingValues,
                            function (missingValue, missingValueIndex) {
                              return _c(
                                "b-badge",
                                {
                                  key: missingValueIndex,
                                  staticClass: "mr-1",
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { variant: "dark" },
                                },
                                [_vm._v(" " + _vm._s(missingValue) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              !this.cleaned
                ? _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { name: "check-button", switch: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.toggleBenchmarking()
                                },
                              },
                              model: {
                                value: _vm.enableBenchmarks,
                                callback: function ($$v) {
                                  _vm.enableBenchmarks = $$v
                                },
                                expression: "enableBenchmarks",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultRawTab.metadataWidget.enableBenchmark"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }