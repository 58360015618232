<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import { CSAnswerComparisonChart } from "@/redem/datafiles/result-charts.js";

export default {
  props: {
    datafileId: String,
    isCleaned: Boolean
  },
  data() {
    return {
      averagePredictionData: null,
      averagePredictionChartData: [],
      averagePredictionChartOptions: []
    };
  },
  async created() {
    await this.fetchChartData();
  },
  methods: {
    async fetchChartData() {
      await DatafileResultService.getAvgPredictionData(this.datafileId)
        .then(data => {
          this.averagePredictionData = data;

          for (let i = 0; i < this.averagePredictionData.length; i++) {
            let sampleChartDataArray = [
              {
                name: "Same Answer",
                data: []
              },
              {
                name: "Different Answers",
                data: []
              }
            ];

            let averagePredictionChartOptions = new CSAnswerComparisonChart();

            for (
              let j = 0;
              j < this.averagePredictionData[i].data.length;
              j++
            ) {
              sampleChartDataArray[0].data.push(
                this.averagePredictionData[i].data[j].sameAnswer
              );

              sampleChartDataArray[1].data.push(
                this.averagePredictionData[i].data[j].differentAnswer
              );

              averagePredictionChartOptions.chartOptions.xaxis.categories.push(
                "Answer " + (j + 1)
              );
            }
            this.averagePredictionChartData.push(sampleChartDataArray);
            this.averagePredictionChartOptions.push(
              averagePredictionChartOptions
            );
          }

          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    }
  }
};
</script>

<template>
  <div
    class="card"
    :style="[
      this.isCleaned ? { background: '#FFFFFF' } : { background: '#FFF5F4' }
    ]"
  >
    <div class="card-body">
      <div class="card-title">
        {{ $t("datafileResultCSTab.datapointWidget.title") }}
      </div>
      <div class="card-text">
        {{ $t("datafileResultCSTab.datapointWidget.description")}}
      </div>
      <div class="card-text mt-3">
        <b-tabs>
          <b-tab
            v-for="(averagePrediction, averagePredictionIndex) in this
              .averagePredictionChartData"
            :key="averagePredictionIndex"
          >
            <template v-slot:title>
              <span class="d-none d-sm-inline-block text-limt">{{
                averagePredictionData[averagePredictionIndex]
                  .datapointIdentifier
              }}</span>
            </template>
            <div class="p-3">
              <apexchart
                ref="performanceChart"
                class="apex-charts mt-5"
                dir="ltr"
                type="bar"
                height="300"
                :series="averagePrediction"
                :options="
                  averagePredictionChartOptions[averagePredictionIndex]
                    .chartOptions
                "
              ></apexchart>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
