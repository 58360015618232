<script>
import {
  MeanChart,
  DistributionChart,
  TrafficLightChart,
  TimeScorePerformanceChart
} from "@/redem/datafiles/result-charts.js";
import { ALGORITHM_TYPES } from "@/shared/util/const";
import DatafileResultService from "@/api/services/datafileResult.service";

export default {
  props: {
    datapointData: Array
  },
  data() {
    return {
      /******* Data Variables *******/
      datafileId: null,
      chartDataForMeanChart: 0,
      meanChartOptions: null,
      chartDataForTrafficLightChart: [],
      trafficLightChartOptions: null,
      chartDataForDistributionChart: null,
      rateChartData: {},
      distributionChartOptions: null,
      performanceChartOptions: null,
      chartDataForPerformanceChart: {
        name: "Average Social Desrability Score",
        data: []
      },
      isDisplayData: false,
      dataPointIdentifier: null,
      isError: false
    };
  },
  async created() {
    this.datafileId = this.$route.query.datafileId;
    this.dataPointIdentifier = this.datapointData.dataPointIdentifier;
    this.meanChartOptions = new MeanChart();
    this.trafficLightChartOptions = new TrafficLightChart();
    this.distributionChartOptions = new DistributionChart();
    this.performanceChartOptions = new TimeScorePerformanceChart();

    const trafficLightProm = new Promise((resolve, reject) => {
      this.fetchTrafficLightData()
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
    const distribution = this.fetchDistributionData();
    const performance = this.fetchSDSPerformanceData();
    const rate = this.fetchSDSRate();

    try {
      await trafficLightProm;
    } catch (e) {
      console.log("Error fetching SDS distribution data");
    }
    try {
      await distribution;
    } catch (e) {
      console.log("Error fetching SDS distribution data");
    }

    try {
      await performance;
    } catch (e) {
      console.log("Error fetching SDS performance data");
    }

    try {
      await rate;
    } catch (e) {
      console.log("Error fetching SDS rate data");
    }

    this.isDisplayData = true;
  },
  methods: {
    async fetchTrafficLightData() {
      let tempData = await DatafileResultService.getTrafficLightData(
        this.datafileId,
        ALGORITHM_TYPES.SDS,
        this.dataPointIdentifier,
        true
      );
      tempData = tempData.trafficLightValues;
     const trafficLightData = [
        tempData.Red,
        tempData.Yellow,
        tempData.LightGreen,
        tempData.Green,
        tempData.Grey
      ];

      this.chartDataForTrafficLightChart = trafficLightData;
      this.chartDataForMeanChart = tempData.Average;
    },

    async fetchDistributionData() {
      const tempDistributionData =
        await DatafileResultService.getDistributionData(
          this.datafileId,
          ALGORITHM_TYPES.SDS,
          this.dataPointIdentifier,
          true
        );

      this.chartDataForDistributionChart = [
        {
          name: "Number of Respondents",
          data: tempDistributionData
        }
      ];
    },

    async fetchSDSPerformanceData() {
      const tempPerformanceData =
        await DatafileResultService.getSDSPerAnswerOption(
          this.datafileId,
          this.dataPointIdentifier
        );

      this.chartDataForPerformanceChart.data = Object.values(
        tempPerformanceData
      ).map(({ score }) => score);
    },

    async fetchSDSRate() {
      const tempRate = await DatafileResultService.getSDSRate(
        this.datafileId,
        this.dataPointIdentifier,
        true
      );

      this.rateChartData = {
        positive: tempRate.Positive,
        negative: tempRate.Negative
      };
    }
  }
};
</script>
<template>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body" v-if="isDisplayData && !isError">
        <div class="card-title">
          {{ $t("datafileResultSDSTab.datapointWidget.title") }}
          {{ dataPointIdentifier }}
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <b-tabs>
              <!-- Genral -->
              <b-tab active lazy>
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">{{
                    $t("datafileResultSDSTab.datapointWidget.tab.general.title")
                  }}</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    {{
                      $t(
                        "datafileResultSDSTab.datapointWidget.tab.general.description"
                      )
                    }}
                  </div>
                  <!-- SDS Score -->
                  <div class="col-md-4">
                    <apexchart
                      ref="PSMeanChart"
                      class="apex-charts apexChartData mt-5"
                      type="radialBar"
                      height="300"
                      dir="ltr"
                      :series="[chartDataForMeanChart]"
                      :options="meanChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <!-- Distribution Chart -->
                  <div class="col-md-4">
                    <apexchart
                      ref="PSDistributionChart"
                      class="apex-charts mt-5"
                      dir="ltr"
                      type="area"
                      height="300"
                      :series="chartDataForDistributionChart"
                      :options="distributionChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                  <!-- Traffic Light Chart -->
                  <div class="col-md-4">
                    <apexchart
                      ref="PStrafficLightChart"
                      class="apex-charts mt-5 mb-4"
                      height="300"
                      type="pie"
                      dir="ltr"
                      :series="chartDataForTrafficLightChart"
                      :options="trafficLightChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                </div>
              </b-tab>
              <!-- Proformace Chart-->
              <b-tab lazy>
                <template v-slot:title>
                  <span class="d-none d-sm-inline-block">{{
                    $t(
                      "datafileResultSDSTab.datapointWidget.tab.answerOptions.title"
                    )
                  }}</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    {{
                      $t(
                        "datafileResultSDSTab.datapointWidget.tab.answerOptions.description"
                      )
                    }}
                  </div>
                  <div class="col-md-12">
                    <div>
                      <apexchart
                        ref="performanceChart"
                        class="apex-charts"
                        dir="ltr"
                        type="bar"
                        height="300"
                        :series="[chartDataForPerformanceChart]"
                        :options="performanceChartOptions.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
