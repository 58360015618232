<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import DatafileResultService from "@/api/services/datafileResult.service.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import SDSInfoModal from "./SDS-info-modal.vue";

export default {
  props: {
    datafileId: String
  },
  components: {
    SDSInfoModal,
    DataFetchingErrorWidget
  },
  data() {
    return {
      /**** Data Varaibles ****/
      metadata: null,
      /**** Operationsal Varaibles ****/
      isDisplayData: false,
      isFetchingDataError: false,
      isDisplayInfoModal: false
    };
  },
  async created() {
    await this.fetchMetaData();
    this.$emit("dataFetchingDone");
  },
  methods: {
    async fetchMetaData() {
      await DatafileResultService.getSDSMetadata(this.datafileId, true)
        .then(data => {
          this.metadata = data;
          this.isDisplayData = true;
        })
        .catch(e => {
          this.isFetchingDataError = true;
          this.isDisplayData = true;
        });
    },
    clickQualityScoreInfoModal() {
      this.isDisplayInfoModal = true;
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body" v-if="isDisplayData">
      <div class="card-title">
        Social-Desirability-Score
        <i
          class="bx bxs-info-circle font-size-18 text-info bx-tada ml-2"
          style="cursor: pointer"
          @click="clickQualityScoreInfoModal()"
        ></i>
      </div>

      <b-row v-if="!this.isFetchingDataError">
        <!-- All Data Records -->
        <b-col sm="4" class="mb-3">
          <div>
            {{ $t("datafileResultSDSTab.metadataWidget.numberOfDataRecords") }}
          </div>
          <div>
            <strong>{{ metadata.numberOfRecords }}</strong>
          </div>
        </b-col>

        <!-- Data Records without Scores -->
        <b-col sm="4" class="mb-3">
          <div>
            {{
              $t("datafileResultSDSTab.metadataWidget.numberOfInvalidDataRecords")
            }}
          </div>
          <div>
            <strong>{{ metadata.numberOfInvalidRecords }}</strong>
          </div>
        </b-col>

        <!-- Number of Datapoints -->
        <b-col sm="4" class="mb-3">
          <div>
            {{ $t("datafileResultSDSTab.metadataWidget.numberOfDataPoints") }}
          </div>
          <div>
            <strong>{{ metadata.numberOfDatapoints }}</strong>
          </div>
        </b-col>
      </b-row>

      <div class="card-text mt-3" v-if="this.isFetchingDataError">
        <DataFetchingErrorWidget />
      </div>
    </div>

    <!-- Loading untile data get set --->
    <div class="card-body" v-else>
      <div class="row justify-content-center">
        <div class="col-6 text-center">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>

    <!-- Info Modal -->
    <SDSInfoModal
      v-if="isDisplayInfoModal"
      :visible="isDisplayInfoModal"
      @close="isDisplayInfoModal = false"
    />
  </div>
</template>
